import React from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import MaterialUILayout from '../components/material-ui-layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import GilaRiverData from '../components/gila-river-data';
import GranadaData from '../components/granada-data';
import HeartMountainData from '../components/heart-mountain-data';
import JeromeData from '../components/jerome-data';
import ManzanarData from '../components/manzanar-data';
import MinidokaData from '../components/minidoka-data';
import PostonData from '../components/poston-data';
import RohwerData from '../components/rohwer-data';
import TopazData from '../components/topaz-data';
import TuleLakeData from '../components/tule-lake-data';

import BigDCampgroundData from '../components/big-d-campground-data';
import FieldsBrookStreamData from '../components/fields-brook-stream-data';
import FeedMaterialsProductionCenterData from '../components/feed-materials-production-center-data';
import DOEIndianMoundData from '../components/doe-indian-mound-data';
import IndustrialExcessLandfillData from '../components/industrial-excess-landfill-data';
import LaskinPoplarData from '../components/laskin-poplar-data';
import LoveCanalData from '../components/love-canal-data';
import NeaseChemicalData from '../components/nease-chemical-data';
import Site666Data from '../components/site-666-data';
import SummitNationalData from '../components/summit-national-data';

const shortcodes = {
  GilaRiverData,
  GranadaData,
  HeartMountainData,
  JeromeData,
  ManzanarData,
  MinidokaData,
  PostonData,
  RohwerData,
  TopazData,
  TuleLakeData,

  BigDCampgroundData,
  FieldsBrookStreamData,
  FeedMaterialsProductionCenterData,
  DOEIndianMoundData,
  IndustrialExcessLandfillData,
  LaskinPoplarData,
  LoveCanalData,
  NeaseChemicalData,
  Site666Data,
  SummitNationalData,
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        name
        title
        series
        media
        size
        year
        city
        state
        country
        inventory
      }
      body
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
      marginTop: '1.2rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.9rem',
      marginTop: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      marginTop: '3rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
      marginTop: '3rem',
    },
  },
  series: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8rem',
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      marginBottom: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
      marginBottom: '3rem',
    },
  },
  image: {
    maxHeight: '90vh',
  },
  media: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },
  artist: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },

  imgMask: {
    position: 'absolute',
    zIndex: 10,
    height: '100%',
    width: '100%',
    backgroundColor: 'rbga(0,0,0,0)',
    top: 0,
  },
}));

const ArtPageTemplate = ({ data: { mdx: art } }) => {
  let title = art.frontmatter.title;
  let isSacredArchitectures =
    art.frontmatter.series === 'Sacred Architectures' ? true : false;
  let country = art.frontmatter.country;
  const classes = useStyles();

  return (
    <MaterialUILayout>
      <Typography variant="h4" className={classes.series} color="secondary">
        {art.frontmatter.series} Gallery
        {isSacredArchitectures ? `, ${country}` : ''}
      </Typography>

      <GatsbyImage
        image={art.frontmatter.image.childImageSharp.gatsbyImageData}
        className={classes.image}
        alt={art.frontmatter.title}
        objectFit="contain"
      />

      <div
        css={css`
          margin: 1rem auto;
        `}
      >
        <Typography variant="h2" className={classes.title}>
          <em>
            {title}, {art.frontmatter.year}
          </em>
          ©
        </Typography>

        <Typography color="primary" variant="h4" className={classes.artist}>
          by Dr. Masumi Hayashi
        </Typography>

        <Typography variant="h4" className={classes.media}>
          {art.frontmatter.media},
          <br />
          {art.frontmatter.size}
        </Typography>

        <p
          css={css`
            font-size: 1rem;
          `}
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{art.body}</MDXRenderer>
          </MDXProvider>
        </p>
      </div>
    </MaterialUILayout>
  );
};

export default ArtPageTemplate;
